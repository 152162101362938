import "./src/styles/global.css"

import React from "react"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfkX7YZAAAAAO_7ZG5q7oxynG0h62q_eVnN3etg"
      language="it"
    >
      {element}
    </GoogleReCaptchaProvider>
  )
}
